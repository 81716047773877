<template>
  <a-space class="mb-8">
    <template v-for="item in btnOperation">
      <template v-if="item.upload">
        <a-upload
          :key="item.type"
          :showUploadList="false"
          :headers="{
            tenant: $store.state.account.tenant,
            token: $store.state.account.token,
            code: item.permission,
          }"
          v-bind="item.upload"
        >
          <a-button>
            <template #icon>
              <component :is="icons[item.icon || 'UploadOutlined']" />
            </template>
            {{ item.label || '上传' }}
          </a-button>
        </a-upload>
      </template>
      <template v-else-if="item.type === 'add'">
        <a-button :key="item.type" type="primary" @click="handleClick(item)">
          <template #icon>
            <component :is="icons[item.icon || 'PlusOutlined']" />
          </template>
          {{ item.label || '新增' }}
        </a-button>
      </template>
      <template v-else-if="item.type === 'delete'">
        <a-button :key="item.type" @click="handleClick(item)">
          <template #icon>
            <component :is="icons[item.icon || 'DeleteOutlined']" />
          </template>
          {{ item.label || '批量删除' }}
        </a-button>
      </template>
      <template v-else>
        <a-button
          :key="item.type"
          :type="item.btnType"
          @click="handleClick(item)"
        >
          <template #icon>
            <component :is="icons[item.icon]" />
          </template>
          {{ item.label }}
        </a-button>
      </template>
    </template>
  </a-space>
  <a-table
    class="iss-grid"
    rowKey="id"
    size="middle"
    v-bind="$attrs"
    :row-selection="rowSelection"
    :data-source="dataSource"
    :pagination="pagingConfig"
    :loading="loading"
  >
    <template v-for="(index, name) in $slots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>
  </a-table>
</template>

<script>
import { createVNode, toRaw } from 'vue';
import { message, Modal, Space, Table, Upload } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'Gird',
  components: {
    ASpace: Space,
    ATable: Table,
    AUpload: Upload,
  },
  props: {
    code: String,
    url: String,
    search: Object,
    allowSelection: [Boolean, Object],
    btnOperation: Array,
    pagination: {
      type: [Object, Boolean],
      default: () => ({}),
    },
  },
  data() {
    return {
      icons,
      loading: false,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      pagingConfig:
        this.pagination === false ? false : this.setPagination(this.pagination),
    };
  },
  created() {
    if (this.url) {
      const { current, pageSize } = this.pagingConfig;
      pageSize ? this.queryListByPaging(current, pageSize) : this.queryList();
    }
  },
  computed: {
    rowSelection() {
      return this.allowSelection
        ? {
            // fixed: true,
            // columnTitle: ' ',
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.selectedRowKeys = selectedRowKeys;
              this.selectedRows = selectedRows;
            },
            ...this.allowSelection,
          }
        : null;
    },
  },
  watch: {
    search: {
      deep: true,
      handler() {
        const { pageSize } = this.pagingConfig;
        pageSize ? this.queryListByPaging(1, pageSize) : this.queryList();
      },
    },
  },
  methods: {
    setPagination(data) {
      const { onChange, onShowSizeChange } = data;
      return Object.assign(
        {
          showLessItems: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: 10,
          current: 1,
          size: 'default',
          showTotal: (total, range) =>
            `共 ${total} 条 当前显示 ${range[0]}-${range[1]} 条`,
        },
        data,
        {
          onChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onChange?.(current, pageSize)
            ),
          onShowSizeChange: (current, pageSize) =>
            this.queryListByPaging(current, pageSize).then(() =>
              onShowSizeChange?.(current, pageSize)
            ),
        }
      );
    },
    queryListByPaging(current, size) {
      this.loading = true;
      return this.$http
        .get(this.url, {
          params: { current, size, ...this.search },
          code: this.code,
        })
        .then(({ records, total }) => {
          this.dataSource = records;
          this.pagingConfig = Object.assign({}, this.pagingConfig, {
            current,
            pageSize: size,
            total: parseInt(total),
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    queryList() {},
    refreshGrid(isCurrent) {
      const { current, pageSize } = this.pagingConfig;
      pageSize
        ? this.queryListByPaging(isCurrent ? current : 1, pageSize)
        : this.queryList();
    },
    handleClick(item) {
      let permission = item.permission;
      typeof permission === 'string' && (permission = [permission]);
      this.$has(...permission).then(() => {
        if (item.type === 'delete') {
          const length = this.selectedRowKeys.length;
          if (length) {
            Modal.confirm({
              title: `确定要删除选中的 ${length} 条数据吗？`,
              icon: createVNode(icons['ExclamationCircleOutlined']),
              okType: 'danger',
              onOk: () => {
                item.fnClick(toRaw(this.selectedRowKeys));
              },
            });
          } else {
            message.info('请至少选择一条数据');
          }
        } else {
          item.fnClick(toRaw(this.selectedRowKeys));
        }
      });
    },
  },
};
</script>

<style scoped></style>
