<template>
  <a-form
    class="iss-form"
    ref="form"
    :model="form"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 18 }"
  >
    <a-row :gutter="16" type="flex">
      <a-col v-for="item in items" :key="item.key" v-bind="span">
        <template v-if="item.type === 'radio'">
          <a-form-item :name="item.key" :label="item.label">
            <a-radio-group
              v-model:value="form[item.key]"
              :options="item.dataset"
            />
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'range'">
          <a-form-item :label="item.label" class="range-wrapper">
            <a-form-item :name="item.start" class="item">
              <a-input-number
                v-model:value="form[item.start]"
                placeholder="请输入开始值"
              />
            </a-form-item>
            <span class="separator"> ~ </span>
            <a-form-item :name="item.end" class="item">
              <a-input-number
                v-model:value="form[item.end]"
                placeholder="请输入结束值"
              />
            </a-form-item>
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'treeSelect'">
          <a-form-item :name="item.key" :label="item.label">
            <a-tree-select
              v-model:value="form[item.key]"
              :placeholder="`请选择${item.label}`"
              allow-clear
              :tree-data="dataset[`${item.key}List`]"
              v-bind="item.options"
            />
          </a-form-item>
        </template>
        <template v-else-if="item.type === 'select'">
          <a-form-item :name="item.key" :label="item.label">
            <a-select
              v-model:value="form[item.key]"
              :options="dataset[`${item.key}List`]"
              :placeholder="`请选择${item.label}`"
            />
          </a-form-item>
        </template>
        <template v-else>
          <a-form-item :name="item.key" :label="item.label">
            <a-input
              v-model:value="form[item.key]"
              autocomplete="off"
              :placeholder="`请输入${item.label}`"
            />
          </a-form-item>
        </template>
      </a-col>
      <a-col :flex="1">
        <a-form-item class="ta-right" :wrapper-col="{ span: 24 }">
          <a-space>
            <a-button type="primary" @click="handleClickSearch">
              查询
            </a-button>
            <a-button @click="resetFields">重置</a-button>
          </a-space>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import {
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  TreeSelect,
} from 'ant-design-vue';

export default {
  name: 'SearchForm',
  components: {
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ARadioGroup: Radio.Group,
    ARow: Row,
    ASpace: Space,
    AInputNumber: InputNumber,
    ATreeSelect: TreeSelect,
    ASelect: Select,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      form: {},
      dataset: {},
      span: this.getSpan(),
    };
  },
  created() {
    this.items.forEach(item => {
      if (item.url) {
        this.$http.get(item.url, { code: item.code }).then(data => {
          this.dataset[`${item.key}List`] =
            item.valueKey || item.labelKey
              ? data.map(i => ({
                  value: i[item.valueKey || 'value'],
                  label: i[item.labelKey || 'label'],
                }))
              : data;
        });
      } else if (item.dataset) {
        this.dataset[`${item.key}List`] = item.dataset;
      }
    });
  },
  methods: {
    handleClickSearch() {
      let temp = {};
      for (const key in this.form) {
        [undefined, ''].includes(this.form[key]) ||
          (temp[key] = this.form[key]);
      }
      this.$emit('fnSearch', temp);
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.handleClickSearch();
    },
    getSpan() {
      switch (this.items.length) {
        case 1:
          return { sm: 24, md: 12 };
        case 2:
          return { sm: 24, md: 12, lg: 8 };
        default:
          return { sm: 24, md: 12, lg: 8, xl: 6 };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.iss-form .ta-right {
  margin-bottom: 0px;
}
.range-wrapper {
  :deep(.ant-form-item-children) {
    display: flex;
    align-items: center;
    .item {
      margin-bottom: 0;
      flex: 1;
      .ant-input-number {
        width: 100%;
      }
    }
    .separator {
      margin: 0 12px;
    }
  }
}
</style>
